/* eslint-disable camelcase */

import React, { createElement } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

// Local import
import TextField from './BasicFields/TextField'
import NumberField from './BasicFields/NumberField'
import Grade from './QuestionFields/Grade'
import Selection from './QuestionFields/Selection'
import Photos from './QuestionFields/Photos'
import Comment from './QuestionFields/Comment'
import Numeric from './QuestionFields/Numeric'
import Signature from './QuestionFields/Signature'
import Variable from './QuestionFields/Variable'
import DateTime from './QuestionFields/DateTime'
import Title from './ContentFields/Title'
import Picture from './ContentFields/Picture'
import Description from './ContentFields/Description'

const updateErrors = (stateErrors, getErrors) => {
  const errors = getErrors()
  if (stateErrors)
    delete stateErrors.isNew

  if (errors && JSON.stringify(errors) !== JSON.stringify(stateErrors)) {
    stateErrors = { ...errors }
    stateErrors.isNew = true
  }

  return stateErrors
}

// Use an counter to avoid duplicate id generation
let idCounter = 0
const idGenerator = () => `new-${new Date().getTime() + idCounter++}`

class SectionField extends React.Component {
  static collapseList(elementId) {
    window.$(`#${elementId}`).collapse('hide')
  }

  constructor(props) {
    super(props)

    this.state = {
      id: props.section.id,
      name: props.section.name,
      position: props.section.position,
      coefficient: props.section.coefficient,
      has_disabled_status: props.section.has_disabled_status,
      questions: props.section.questions,
      removed_question_ids: [],
      errors: props.section.errors || {}
    }

    this.fieldName = props.fieldName
    this.collectFrequency = props.collectFrequency
  }

  shouldComponentUpdate(_nextProps, nextState) {
    this.state.errors = updateErrors(this.state.errors, this.props.getErrors)

    return (
      this.state.name !== nextState.name ||
      this.state.coefficient !== nextState.coefficient ||
      this.state.position !== nextState.position ||
      this.state.questions.length !== nextState.questions.length ||
      this.state.has_disabled_status !== nextState.has_disabled_status ||
      this.state.errors.isNew === true
    )
  }

  componentDidUpdate() {
    this.props.handleSectionChange(this.state)
  }

  handleNameChange = event => this.setState({ name: event.target.value })

  handleCoefficientChange = event => this.setState({ coefficient: event.target.value })

  handleDisabledStatusChange = event => this.setState({ has_disabled_status: event.target.checked })

  handleOnDragEnd = result => {
    const { destination, source } = result

    if (!destination)
      return

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return

    this.setState(prevState => {
      const movedQuestion = prevState.questions[source.index]
      const newQuestions = prevState.questions

      newQuestions.splice(source.index, 1)
      newQuestions.splice(destination.index, 0, movedQuestion)
      newQuestions.forEach((question, index) => {
        question.position = index
      })

      return { questions: newQuestions }
    })
  }

  duplicateData = () => {
    const duplicatedData = JSON.parse(JSON.stringify(this.state))

    duplicatedData.id = idGenerator()
    duplicatedData.questions = duplicatedData.questions.map(question => {
      const duplicatedQuestion = question

      duplicatedQuestion.id = idGenerator()
      if (typeof duplicatedQuestion.options !== 'undefined') {
        duplicatedQuestion.options = duplicatedQuestion.options.map(option => {
          const duplicatedOption = option

          duplicatedOption.id = idGenerator()

          return duplicatedOption
        })
      }

      return duplicatedQuestion
    })

    duplicatedData.removed_question_ids = []
    duplicatedData.errors = {}
    return duplicatedData
  }

  questionsCollapseId = () => `questions_collapse__${this.state.id}`

  contentsCollapseId = () => `contents_collapse__${this.state.id}`

  handleClickNewContent = context => event => {
    event.preventDefault()
    SectionField.collapseList(this.contentsCollapseId())

    this.setState(prevState => {
      const newContent = {
        id: idGenerator(),
        title: '',
        description: null,
        context,
        position: prevState.questions.length,
        mandatory: false,
        variable_id: null,
        not_applicable: false,
        options: []
      }

      return { questions: prevState.questions.concat(newContent) }
    })
  }

  handleClickNewquestion = context => event => {
    event.preventDefault()
    SectionField.collapseList(this.questionsCollapseId())

    this.setState(prevState => {
      const newQuestion = {
        id: idGenerator(),
        title: '',
        context,
        position: prevState.questions.length,
        mandatory: false,
        variable_id: null,
        not_applicable: false,
        options: []
      }

      return { questions: prevState.questions.concat(newQuestion) }
    })
  }

  addQuestionButton = (context, label) => (
    <button
      type="button"
      className="rounded btn btn-primary mb-1 add-question mr-1"
      onClick={this.handleClickNewquestion(context)}
    >
      {label}
    </button>
  )

  addContentButton = (context, label) => (
    <button
      type="button"
      className="rounded btn btn-primary mb-1 add-content mr-1"
      onClick={this.handleClickNewContent(context)}
    >
      {label}
    </button>
  )

  handleQuestionDuplicate = questionId => () => {
    this.setState(prevState => {
      const updatedQuestions = [...prevState.questions]
      // Find the question to duplicate
      const questionToDuplicate = { ...updatedQuestions.find(question => question.id === questionId) }
      questionToDuplicate.id = idGenerator()

      // Duplicate it options if any
      if (typeof questionToDuplicate.options !== 'undefined' && questionToDuplicate.options.length > 0) {
        const duplicatedOptions = questionToDuplicate.options.map(option => ({ ...option }))
        duplicatedOptions.forEach(option => {
          option.id = idGenerator()
        })
        questionToDuplicate.options = duplicatedOptions
      }

      // Insert the duplicated question at the right position
      updatedQuestions.splice(questionToDuplicate.position, 0, questionToDuplicate)
      // Update the position of all questions
      updatedQuestions.forEach((question, index) => {
        question.position = index
      })
      return { questions: [...updatedQuestions] }
    })
  }

  handleQuestionChange = index => question => {
    this.setState(prevState => {
      const { questions } = prevState

      questions[index] = question

      return { questions }
    })
  }

  handleQuestionRemove = questionId => () => {
    this.setState(prevState => {
      let newlyRemovedQuestions = [...prevState.removed_question_ids]
      const questions = prevState.questions.filter(question => question.id !== questionId)

      if (!String(questionId).includes('new-'))
        newlyRemovedQuestions = prevState.removed_question_ids.concat(questionId)

      questions.forEach((question, index) => {
        question.position = index
      })

      return { questions, removed_question_ids: newlyRemovedQuestions }
    })
  }

  getErrors = index => () => this.state.errors[`questions[${index}]`]

  draggableQuestions = () => {
    const questionComponents = {
      grade: Grade,
      photos: Photos,
      selection: Selection,
      selection_multi: Selection,
      comment: Comment,
      numeric: Numeric,
      signature: Signature,
      variable: Variable,
      datetime: DateTime,
      title: Title,
      picture: Picture,
      description: Description
    }

    return (
      this.state.questions.map((question, index) => (
        <Draggable
          key={`draggable-question-${index}`}
          draggableId={`draggable-question-${index}`}
          index={index}
        >
          {provided => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              {Boolean(questionComponents[question.context]) &&
                createElement(questionComponents[question.context], {
                  key: question.id,
                  name: `${this.fieldName}.questions[${index}]`,
                  question,
                  data: this.props.data,
                  handleQuestionChange: this.handleQuestionChange(index),
                  handleQuestionRemove: this.handleQuestionRemove,
                  handleQuestionDuplicate: this.handleQuestionDuplicate,
                  getErrors: this.getErrors(question.id),
                  dragHandleProps: provided.dragHandleProps
                })}
              <hr />
            </div>
          )}
        </Draggable>
      ))
    )
  }

  render() {
    return (
      <div className="nested-fields nested-section" >
        <div className="header-nested-section c-pointer">
          <i className="fas fa-arrows-alt" {...this.props.dragHandleProps} />
          <h5 className="mb-0 ml-1 d-inline-block">{this.state.name}</h5>
          <div className="float-right">
            <div
              className="d-inline-block mr-2"
              onClick={this.props.handleDuplicateSection(this.duplicateData)}
            >
              <i className="fas fa-clone text-secondary" />
            </div>
            <div className="d-inline-block" onClick={this.props.handleRemoveSection}>
              <i className="fas fa-trash danger-hover" />
            </div>
          </div>
        </div>
        <div className="inner-nested-section">
          <div className="row">
            <div className="col-md-8">
              <TextField
                name={`${this.fieldName}.name`}
                label="Nom de la section"
                value={this.state.name}
                errors={this.state.errors.name}
                onChange={this.handleNameChange}
              />
            </div>
            <div className="col-md-4">
              <NumberField
                type="number"
                name={`${this.fieldName}.coefficient`}
                label="Coefficient de la section"
                step={1}
                value={this.state.coefficient}
                errors={this.state.errors.coefficient}
                onChange={this.handleCoefficientChange}
              />
            </div>
          </div>
          {
            this.collectFrequency === 'recurrent' && (
              <div className="custom-control custom-switch">
                <input
                  id={`has-disabled-status-${this.state.id}`}
                  type="checkbox"
                  className="custom-control-input custom-switch-lg"
                  checked={this.state.has_disabled_status}
                  onChange={this.handleDisabledStatusChange}
                />
                <label className="custom-control-label" htmlFor={`has-disabled-status-${this.state.id}`}>
                  Avec statut non applicable
                </label>
              </div>
            )
          }
          <hr />
          <div className="question-list">
            <DragDropContext onDragEnd={this.handleOnDragEnd}>
              <Droppable droppableId="droppable-questions">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {this.draggableQuestions()}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <button
            className="btn btn-outline-primary mb-2"
            type="button"
            data-target={`#${this.questionsCollapseId()}`}
            data-toggle="collapse"
            onClick={SectionField.collapseList(this.contentsCollapseId())}
          >
            Ajouter une question
          </button>
          <button
            className="btn btn-outline-primary mb-2 ml-2"
            type="button"
            data-target={`#${this.contentsCollapseId()}`}
            data-toggle="collapse"
            onClick={SectionField.collapseList(this.questionsCollapseId())}
          >
            Ajouter du contenu
          </button>
          <div className="row">
            <div className="col">
              <div className="collapse multi-collapse" id={this.questionsCollapseId()}>
                {this.addQuestionButton('grade', 'Notation')}
                {this.addQuestionButton('photos', 'Photos')}
                {this.addQuestionButton('selection', 'Choix unique')}
                {this.addQuestionButton('selection_multi', 'Choix multiples')}
                {this.addQuestionButton('comment', 'Commentaire')}
                {this.addQuestionButton('numeric', 'Numérique')}
                {this.addQuestionButton('signature', 'Signature')}
                {this.addQuestionButton('variable', 'Variable')}
                {this.addQuestionButton('datetime', 'Date')}
              </div>
              <div className="collapse multi-collapse" id={this.contentsCollapseId()}>
                {this.addContentButton('title', 'Titre')}
                {this.addContentButton('picture', 'Image')}
                {this.addContentButton('description', 'Description')}
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default SectionField
export { updateErrors }
